import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import DefaultHeader from "../../components/default-header";
import Footer from "../../components/footer";
import GdprPanel from "../../components/gdpr";
import Navigation from "../../components/navigation";
import Seo from "../../components/seo";
import NewsletterSignup from "../../components/newsletterSignUp";

const Page = () => {
  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline="Privacy Policy"
          heroBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-2023.webp')"
          heroMobileBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-mobile-2023.webp')"
        />
        <div id="body">
          <Container fluid="lg">
            <Row>
              <Col lg={12}>
                <Row className="white-bg mb-5">
                  <Col className="p-4">
                    <h1>Meridian University’s Privacy Statement</h1>
                    <p>
                      Meridian University respects the privacy of all website
                      visitors. The purpose of this policy is to alert users
                      about the ways that the site collects, processes, and
                      protects your personal information. Meridian University
                      will never sell any personal, mail or phone information to
                      any other companies or organizations.
                    </p>
                    <p>
                      The information we collect is used to improve the content
                      of our Web page. Meridian’s website does not require you
                      to share information that identifies you personally, such
                      as your name or e-mail address, in order for you to use
                      the website.
                    </p>
                    <p>
                      Please note that this policy applies only to Meridian
                      University’s website and not to the websites of other
                      companies or organizations to which we provide links or to
                      any software that may be downloaded from our website.
                      Please be aware that Meridian University is not
                      responsible for the privacy practices of such other sites.
                      We encourage our users to be aware when they leave our
                      site and to read the privacy statements of each and every
                      website that collects personally identifiable information.
                      This privacy statement applies solely to information
                      collected by this website.
                    </p>
                    <h2>E-mail and E-mail Addresses</h2>
                    <p>
                      If you send an e-mail to us, or fill out our Request for
                      Information form, we will collect your e-mail address and
                      the full content of your e-mail, including (if applicable)
                      any attached files, and other information you provide. We
                      will use your email address to contact you with
                      promotional notifications regarding upcoming events,
                      newsletters, etc. You may indicate your preference to stop
                      receiving further promotional communications. You have a
                      right at any time to stop us from contacting you for
                      marketing purposes. When you receive promotional
                      communications from us, you may indicate a preference to
                      stop receiving further promotional communications from us
                      and you will have the opportunity to "opt-out" by
                      following the unsubscribe instructions provided in the
                      promotional e-mail you receive or by contacting us
                      directly at{" "}
                      <a href="mailto:info@meridianuniversity.edu">
                        info@meridianuniversity.edu
                      </a>
                      .
                    </p>
                    <h2>Admissions SMS/Texting</h2>
                    <p>
                      Our Admissions Team uses SMS/texting to communicate with
                      prospective students and applicants. By completing the
                      admissions application online, filling out a form on our
                      website, booking an advising appointment, or using the
                      LiveChat feature, you are opting-in to receiving text
                      messages from Meridian University. You can cancel at any
                      time by texting STOP to one of the messages.
                    </p>
                    <h2>Cookies</h2>
                    <p>
                      "Cookies" are small pieces of information that a website
                      sends to your computer’s hard drive while you are viewing
                      a website. We may use both session Cookies (which expire
                      once you close your web browser) and persistent Cookies
                      (which stay on your computer until you delete them) to
                      provide you with a more personal and interactive
                      experience. If you prefer not to receive cookies from the
                      website, you have several options to allow for this in
                      your browser configuration. Disabling cookies, however,
                      may limit your access to information or features of
                      certain areas of the site. Meridian University uses Google
                      Analytics to monitor your activity on this website.
                      Cookies are saved on your computer, and therefore you
                      control them.
                    </p>
                    <h2>Online Advertising We Use</h2>
                    <p>
                      We use Google AdWords Remarketing to advertise trigger
                      across the Internet. AdWords remarketing will display
                      relevant ads tailored to you based on what parts of the
                      website you have viewed by placing a cookie on your
                      machine. This cookie does not in anyway identify you or
                      give access to your computer. Google AdWords Remarketing
                      allows us to tailor our marketing to better suit your
                      needs and only display ads that are relevant to you.
                    </p>
                    <h2>
                      Acceptance of the Meridian University website Privacy
                      Policy Terms and Conditions
                    </h2>
                    <p>
                      By using this site, you signify your agreement to the
                      terms and conditions of this Meridian University website
                      Privacy Policy. If you do not agree to these terms and
                      conditions, please do not use the site. We reserve the
                      right, at our sole discretion, to change, modify, add, or
                      remove portions of this policy at any time. Please check
                      this page periodically for any changes. Your continued use
                      of the Meridian University website following the posting
                      of any changes to these terms shall mean that you have
                      accepted those changes. If you have questions or comments
                      about our privacy policy, please contact us at the
                      following email address:{" "}
                      <a href="mailto:info@meridianuniversity.edu">
                        info@meridianuniversity.edu
                      </a>
                      .
                    </p>
                    <h2>Information Collection</h2>
                    <p>Meridian University ("we," "us," or "our") respects your privacy. This policy explains how we collect, use, and protect the information you provide when you submit your personal information.</p>
                    <p><strong>Purpose of Use</strong></p>
                    <p>The information we collect is used to communicate with you, provide information about our services or products, and address your inquiries. We may also use this data for marketing and research purposes to improve our offerings.</p>
                    <p><strong>Data Sharing</strong></p>
                    <p>We may share your information with our marketing and sales partners who assist us in processing your requests. These third parties are contractually obligated to protect your data and may not use it for any purpose other than as specified.</p>
                    <p><strong>Data Retention and Security</strong></p>
                    <p>We retain your data only as long as necessary to fulfill the purposes outlined in this policy. We implement reasonable security measures to protect your data from unauthorized access.</p>
                    <p><strong>Your Rights</strong></p>
                    <p>You have the right to access, correct, delete, or restrict the use of your data. To exercise these rights, contact us at <a href="mailto:info@meridianuniversity.edu">info@meridianuniversity.edu</a>.</p>
                    <p><strong>Consent and Opt-Out</strong></p>
                    <p>By submitting your information, you consent to our use of your data as described in this policy. You can opt-out of future communications by clicking the unsubscribe link in our emails or contacting us.</p>
                    <p><strong>Contact Information</strong></p>
                    <p>For privacy-related inquiries, please contact us at <a href="mailto:info@meridianuniversity.edu">info@meridianuniversity.edu</a>.</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = () => (
  <Seo
    title="Privacy Policy"
    description="This Privacy Policy describes the information Meridian University collects about the users of its website, how and why Meridian University collects users' information, how the information is processed, and how is personal information protected."
    pathname="/privacy"
  />
);

export default Page;
